import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCwAJJ2KRDGCXNl-3kUZ58yzi54oj81QTI',
  authDomain: 'mikrobit-8f3d0.firebaseapp.com',
  projectId: 'mikrobit-8f3d0',
  storageBucket: 'mikrobit-8f3d0.appspot.com',
  messagingSenderId: '981178008281',
  appId: '1:981178008281:web:a6be64b5905237246df4c8',
}

const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)

import React from 'react'
import * as BsIcons from 'react-icons/bs'
import * as FaIcons from 'react-icons/fa'
import * as Fa6Icons from 'react-icons/fa6'

const Icon = (props) => {
  const { iconName, size, color, tpIcon } = props
  let style = { display: 'flex', fontSize: size, color: color }
  if (tpIcon === 'Bs') return <div style={style}>{React.createElement(BsIcons[iconName])}</div>
  else if (tpIcon === 'Fa') return <div style={style}>{React.createElement(FaIcons[iconName])}</div>
  else if (tpIcon === 'Fa6') return <div style={style}>{React.createElement(Fa6Icons[iconName])}</div>
}

export default function ({ heading, subheading, children, tpIcon = 'Bs', icon = 'BsWindow' }) {
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className={'page-title-icon'}>
            <div style={{ margin: 'auto' }}>
              <Icon tpIcon={tpIcon} iconName={icon} size={28} color="var(--primary)" />
            </div>
          </div>
          <div>
            {heading}
            {subheading && <div className={'page-title-subheading'}>{subheading}</div>}
          </div>
        </div>
        {children && <div className="page-title-actions">{children}</div>}
      </div>
    </div>
  )
}

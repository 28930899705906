import { createStore } from 'redux'
//import { loadState, saveState } from './localStorage'

function storeAppL(
  state = {
    enableMobileMenu: false,
    enableClosedSidebar: false,
    enableMobileMenuSmall: false,
    contas: [],
    chatFilters: {},
    chatContent: {},

    chatInbox: [],
    tarefas: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'enableMobileMenu':
      return { ...state, enableMobileMenu: action.value }
    case 'enableClosedSidebar':
      return { ...state, enableClosedSidebar: action.value }
    case 'enableMobileMenuSmall':
      return { ...state, enableMobileMenuSmall: action.value }
    case 'contas':
      return { ...state, contas: action.value }
    case 'chatFilters':
      return { ...state, chatFilters: action.value }
    case 'chatContent':
      return { ...state, chatContent: action.value }

    case 'chatInbox':
      return { ...state, chatInbox: action.value }
    case 'tarefas':
      return { ...state, tarefas: action.value }
  }
  return state
}

//const persistedState = loadState()
//const store = createStore(storeAppL, persistedState)
const store = createStore(storeAppL)
// store.subscribe(() => {
//   saveState(store.getState())
// })

export default store

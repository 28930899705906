import React from 'react'
import { DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap'
import { FaAngleDown } from 'react-icons/fa'
import avatar1 from '../../assets/images/user.png'
import { getUser, putUser } from '../../Reducers/storageApp'
import { useState } from 'react'
import { ContaGetByFilter } from '../../Service/ApiService'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Tasks from './Tasks'
import Chats from './Chats'
import { Biz_IsAccessCodigo } from '../../Service/BizService'

function UserBox() {
  const user = getUser()
  const [contas, setContas] = useState([])
  const [values, setValues] = useState()

  const dispatch = useDispatch()
  const contasStore = useSelector((state) => state.contas)

  function onSair() {
    localStorage.clear()
    window.location.href = '/#/login'
  }

  // async function loadContas() {
  //   const { contaId, id: usuarioId } = await getUser()
  //   var response = await ContaGetByFilter({ ativo: true, usuarioId })
  //   response.forEach((item) => {
  //     if (item.contaId === contaId) setValues({ value: item.contaId, label: item.nome })
  //   })
  //   setContas(response)
  //   dispatch({ type: 'contas', value: response })
  // }

  // function onConta(ev) {
  //   setValues({ ev })
  //   putUser({ contaId: ev.contaId, contaNm: ev.nome })
  //   window.location.reload()
  // }

  // useEffect(() => {
  //   if (contasStore?.lenght > 0) setContas(contasStore)
  //   else loadContas()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <>
      {/* <div className="header-dots pr-0">
        <div className="widget-content">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-2 header-user-info">
              <div className="widget-heading text-center"></div>
            </div>

            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} color="link" className="p-0">
                  <div className="widget-content-left header-user-info">
                    <div style={{ marginRight: '5px', color: '#000' }} className="widget-heading">
                      {values?.label ? values?.label : 'Conta'}
                    </div>
                  </div>
                  <img width={34} className="rounded-circle" src={Contas} alt="" />
                  <FaAngleDown className="ml-2 opacity-8 heading-icon" color="var(--color-menu)" size={18} />
                </DropdownToggle>

                <DropdownMenu className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    {contas.map((d) => (
                      <NavItem key={d.contaId}>
                        <NavLink className={d.contaId == values?.value ? 'heading-selected' : ''} onClick={() => onConta(d)}>
                          {d.nome}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div> */}

      {Biz_IsAccessCodigo('Chat_Q') && <Chats />}
      {Biz_IsAccessCodigo('Tasks_Q') && <Tasks />}

      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-2">
              <UncontrolledButtonDropdown>
                <DropdownToggle style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} color="link" className="p-0">
                  <div className="widget-content-left  header-user-info">
                    <div style={{ marginRight: '5px', color: '#000' }} className="widget-heading">
                      {user?.name}
                    </div>
                  </div>
                  <img width={34} className="rounded-circle" src={user?.imgSource ? user.imgSource : avatar1} alt="" />
                  <FaAngleDown className="ml-2 opacity-8 heading-icon" color="var(--color-menu)" size={18} />
                </DropdownToggle>

                <DropdownMenu style={{ minWidth: '15rem' }} className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem>
                      <NavLink href="/#/Settings/User/Profile">Perfil</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink onClick={onSair}>Sair</NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserBox

import React from 'react'
import MetisMenu from 'react-metismenu'
import { getUserMenu } from '../../Reducers/storageApp'

export default function AppNav() {
  const menu = getUserMenu()
  let menuRender
  menuRender = (
    <>
      <MetisMenu content={menu} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />
    </>
  )

  return <>{menuRender}</>
}

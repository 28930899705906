import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => 'dd/MM/yyyy',
  },
}

function InputComponent({ value, onChange, ...inputProps }) {
  const [values, setValues] = useState([null, null])

  useEffect(() => {
    setValues(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInput = (ev) => {
    setValues(ev)
    onChange({ target: { value: ev, ...inputProps } })
  }

  return (
    <DatePicker locale={locale} dateFormat="dd/MM/yyyy" className="form-control" selectsRange={true} startDate={values[0]} endDate={values[1]} onChange={onInput} isClearable={true} {...inputProps} />
  )
}

export default InputComponent

import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'

export default function InputComponent({ value, label, onChange, styleContainer = {}, styleLabel = {}, ...inputProps }) {
  const [state, setstate] = useState(false)

  useEffect(() => {
    if (value != undefined) setstate(value)
  }, [value])

  const onSelect = (ev) => {
    const { checked } = ev.target
    setstate(checked)

    if (onChange) onChange({ target: { value: checked, ...inputProps } })
  }

  return (
    <Container style={styleContainer}>
      <label className="checkbox">
        <SelectBox type="checkbox" value={state} defaultChecked={value} onClick={onSelect} {...inputProps} />
        <span className="checkmark"></span>
      </label>
      <Label style={styleLabel}>{label}</Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 15px;
`
const SelectBox = styled(Input)``
const Label = styled.span`
  color: ${(props) => props.isHighlight && 'var(--primary)'};
  font-size: ${(props) => props.isHighlight && '1.5rem'};
  font-weight: ${(props) => props.isHighlight && '600'};
  /* padding-top: 7px; */
`

import { FaRegCheckCircle } from 'react-icons/fa'
import { ChatContainer, ChatQtde } from './styles'
import { useEffect, useState } from 'react'
import { TarefaGet } from '../../Service/ApiService'
import { getUser } from '../../Reducers/storageApp'
import { useDispatch, useSelector } from 'react-redux'
import audio from '../../assets/new-task.mp3'

export default function Tasks() {
  const dispatch = useDispatch()
  const tarefas = useSelector((state) => state.tarefas)
  const [count, setCount] = useState(0)
  const [refTimer, setRefTimer] = useState(0)

  //timer
  function startTimer() {
    try {
      loadTarefas()
      if (refTimer != 0) clearInterval(refTimer)

      let newTimerId = setInterval(() => {
        setCount((count) => count + 1)
      }, 60000)

      setRefTimer(newTimerId)
    } catch {}
  }

  async function loadTarefas() {
    const { contaId, id: usuarioId } = await getUser()
    let hasNew = false
    try {
      const response = await TarefaGet({ contaId, usuarioId, situacao: [10, 20] })

      response.forEach((d) => {
        if (tarefas?.filter((f) => f.tarefaId == d.tarefaId)?.length === 0) hasNew = true
      })
      if (hasNew) new Audio(audio).play()
      dispatch({ type: 'tarefas', value: response })
    } catch {}
  }

  useEffect(() => {
    try {
      loadTarefas()
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  useEffect(() => {
    startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="header-dots pr-0">
      <div className="widget-content">
        <div className="widget-content-wrapper">
          <div className="widget-content-left header-user-info">
            <div className="widget-heading text-center"></div>
          </div>

          <ChatContainer className="p-0" onClick={() => (window.location.href = '/#/Tasks')}>
            <FaRegCheckCircle size={28} />
            {tarefas?.length > 0 && <ChatQtde color="#3f6ad8">{tarefas?.length}</ChatQtde>}
          </ChatContainer>
        </div>
      </div>
    </div>
  )
}

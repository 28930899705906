import { ChatContainer, ChatQtde } from './styles'
import { useEffect, useState } from 'react'
import { ChatConversaGetForSidebar, ChatFilaGet } from '../../Service/ApiService'
import { getUser } from '../../Reducers/storageApp'
import { useDispatch, useSelector } from 'react-redux'
import audio from '../../assets/new-chat.mp3'
import { BsChatDots } from 'react-icons/bs'

export default function Tasks() {
  const dispatch = useDispatch()
  const chatInbox = useSelector((state) => state.chatInbox)

  const [count, setCount] = useState(0)
  const [refTimer, setRefTimer] = useState(0)
  const [filters, setFilters] = useState()

  // //timer
  function startTimer() {
    try {
      loadChat()
      if (refTimer != 0) clearInterval(refTimer)

      let newTimerId = setInterval(() => {
        setCount((count) => count + 1)
      }, 10000)

      setRefTimer(newTimerId)
    } catch {}
  }

  async function loadChat() {
    let hasNew = false
    try {
      if (!filters) return

      const response = await ChatConversaGetForSidebar(filters)
      response.forEach((d) => {
        if (chatInbox?.filter((f) => f.mensagemId == d.mensagemId)?.length === 0) hasNew = true
      })
      if (hasNew) new Audio(audio).play()
      dispatch({ type: 'chatInbox', value: response })
    } catch {}
  }

  useEffect(() => {
    try {
      loadChat()
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  useEffect(() => {
    async function load() {
      const { contaId, id: usuarioId, perfil } = await getUser()
      let filas = []
      let respFilas = await ChatFilaGet({ contaId, usuarioId, ativo: true })
      if (respFilas?.length == 0 && perfil === 'A') respFilas = await ChatFilaGet({ contaId, ativo: true })

      respFilas.forEach((d) => {
        filas.push(d.chatFilaId)
      })

      setFilters({ situacao: 'OP', tpBox: 'I', contaId, usuarioId, filas, usuarioAll: false })
      startTimer()
    }

    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="header-dots pr-0">
      <div className="widget-content">
        <div className="widget-content-wrapper">
          <div className="widget-content-left header-user-info">
            <div className="widget-heading text-center"></div>
          </div>

          <ChatContainer className="p-0" onClick={() => (window.location.href = '/#/Chat')}>
            <BsChatDots size={28} />
            {chatInbox?.length > 0 && <ChatQtde color="#25d366">{chatInbox?.length}</ChatQtde>}
          </ChatContainer>
        </div>
      </div>
    </div>
  )
}

import styled from 'styled-components'

export const ChatContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  :hover {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`

export const ChatQtde = styled.div`
  background-color: red;
  margin: -20px 0 0 -8px;

  min-width: 20px;
  height: 20px;
  border-radius: 50px;
  padding: 0 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.75rem;
  color: #fff;
  background-color: ${(props) => (props.color ? props.color : 'red')};
  font-weight: 500;
  line-height: 1;
`

import { Route, Navigate, Routes, HashRouter, useLocation } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import { ToastContainer } from 'react-toastify'
import { getToken } from '../../Reducers/storageApp'

import Login from '../../Pages/Login'
import Home from '../../Pages/Home'

const Dash = lazy(() => import('../../Pages/Dash'))
const Chat = lazy(() => import('../../Pages/Chat'))
const Settings = lazy(() => import('../../Pages/Settings'))
const Campaign = lazy(() => import('../../Pages/Campaign'))
const Crm = lazy(() => import('../../Pages/Crm'))
const Project = lazy(() => import('../../Pages/Project'))
const Tasks = lazy(() => import('../../Pages/Tasks'))
const Clients = lazy(() => import('../../Pages/Clients'))
const Reports = lazy(() => import('../../Pages/Reports'))
const Property = lazy(() => import('../../Pages/Property'))

const PrivateRoute = ({ component: Component }) => {
  const token = getToken()
  if (!token) return (window.location.href = '/#/Login')
  return Component
}

export default function App() {
  return (
    <>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">
                Aguarde enquanto carregamos todos os componentes
                <small> ... </small>
              </h6>
            </div>
          </div>
        }
      >
        <HashRouter>
          <Routes>
            <Route path="/" exact element={<PrivateRoute component={<Home />} />} />
            <Route path="/Home" element={<PrivateRoute component={<Home />} />} />
            <Route exact path="/" element={<PrivateRoute component={<Navigate to="/Home" />} />} />

            <Route path="/CRM/*" element={<PrivateRoute component={<Crm />} />} />
            <Route path="/Dashboard" element={<PrivateRoute component={<Dash />} />} />
            <Route path="/Chat" element={<PrivateRoute component={<Chat />} />} />
            <Route path="/Chat/:id" element={<PrivateRoute component={<Chat />} />} />
            <Route path="/Campaign/*" element={<PrivateRoute component={<Campaign />} />} />
            <Route path="/Settings/*" element={<PrivateRoute component={<Settings />} />} />
            <Route path="/Project/*" element={<PrivateRoute component={<Project />} />} />
            <Route path="/Tasks/*" element={<PrivateRoute component={<Tasks />} />} />
            <Route path="/Clients/*" element={<PrivateRoute component={<Clients />} />} />
            <Route path="/Reports/*" element={<PrivateRoute component={<Reports />} />} />
            <Route path="/Property/*" element={<PrivateRoute component={<Property />} />} />

            <Route path="/login/*" element={<Login />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </HashRouter>
      </Suspense>

      <ToastContainer />
    </>
  )
}

function NoMatch() {
  let location = useLocation()

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <h3>Página não encontrada ou acesso negado</h3>
      {location.pathname !== '/404' && (
        <h3>
          <code>{location.pathname}</code>
        </h3>
      )}
      <a href="#" className="page-link mt-4" onClick={() => (window.location.href = '/#/')}>
        Voltar para home
      </a>
    </div>
  )
}

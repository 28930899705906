const objContrato = `
    <p>
      Estes Termos e Condições de Uso da Plataforma MikroBit (“<strong>Termos de Uso</strong>” ou “<strong>Termos</strong>”) regulamentam a utilização e contratação da Plataforma, disponibilizada pela <strong>BITYS LTDA</strong>, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 26.197.870/0001-96, com sede na Rua Marechal Deodoro, nº 431, 5° andar, centro, CEP 80020-320,Curitiba/PR.
    </p>
    <p>
      <strong>Ao aderir e acessar a Plataforma, você reconhece e concorda, de forma livre, espontânea, plena e sem reservas, com o presente Termos de Uso, bem como com as demais políticas disponíveis na Plataforma. Ainda, para facilitar a leitura e compreensão, sempre que mencionarmos “Você” ou “Cliente”, estamos nos referindo ao contratante, e, quando mencionarmos “MikroBit” ou “Nós”, estamos nos referindo à Bitys Ltda.</strong>
    </p>
    
    <h3>1. DEFINIÇÕES</h3>
    <div class="divgpitens">
      <strong>1.1.</strong> 
      Sem prejuízo às demais definições previstas nestes Termos, e para fins de sua interpretação, as seguintes palavras são definidas da seguinte forma:

      <div class="divgpitens">
        <p>
          <strong>Administrador</strong>: Usuário que possui o acesso integral ao sistema, detentor de login e senha cadastrados no momento da contratação, podendo ser o próprio Cliente, se pessoa física, ou seu representante legal, aqui considerado aquele que for designado pela empresa para deter e gerir o acesso principal à Plataforma.
        </p>
        <p>
          <strong>Informação Confidencial</strong>: Documentos, equipamentos,
          software, dados, inclusive e especialmente, banco de dados, materiais,
          desenhos, dentre outros, seja de natureza técnica, operacional,
          logística, econômica, de engenharia ou de qualquer outra natureza,
          entregues, revelados ou fornecidos, bem como todos e quaisquer
          assuntos e temas tratados, informações sobre pesquisa, desenvolvimento
          técnico, modelos, aspectos comerciais passados, presentes e futuros,
          experiências e resultados de atividades, simulações lógicas,
          correspondências e elementos técnicos, independentemente da
          necessidade de identificação por Você ou pela Bitys de sua natureza
          confidencial.
        </p>
        <p>
          <strong>LGPD</strong>: Lei nº 13.709/2018 – Dispõe sobre o tratamento
          de dados pessoais, inclusive nos meios digitais, por pessoa natural ou
          por pessoa jurídica de direito público ou privado, com o objetivo de
          proteger os direitos fundamentais de liberdade e de privacidade e o
          livre desenvolvimento da personalidade da pessoa natural.
        </p>
        <p>
          <strong>Plataforma</strong>: Ambiente virtual Bitys, disponibilizado
          por meio de um software online, em nuvem, onde os Usuários podem se
          cadastrar e utilizar os serviços do MikroBit, conforme por ela
          disponibilizado e definido nestes Termos.
        </p>
        <p>
          <strong>Política(s)</strong>: Política de Privacidade, Política de
          Cookies e demais políticas da Bitys disponíveis e/ou a serem
          disponibilizadas que se tornarão disponíveis em suas páginas públicas.
        </p>
        <p>
          <strong>Usuário(s)</strong>: Todo aquele que possui acesso à
          Plataforma, seja ele Administrador, Gestor, Colaborador ou terceiro ao
          qual seja autorizada ou fornecido o acesso para utilização dos
          Serviços.
        </p>
      </div>
    </div>

    <h3>2. SERVIÇOS</h3>
    <div class="divgpitens">
      <p>
        <strong>2.1.</strong>
        Serviços da Plataforma. Os Serviços da Bitys, objeto destes Termos trata
        da disponibilização de acesso à Plataforma, no formato Software as a
        Service (SaaS ou Software como serviço) e as is, as available (como
        está, como disponível), a qual possibilita, mediante prévia contratação,
        o controle de atendimentos, por meio da Plataforma, dentro dos limites
        das obrigações e direitos previstas nestes Termos.
      </p>
      <p>
        <strong>2.2.</strong>
        Mudança de funcionalidades. As funcionalidades da Plataforma poderão
        sofrer alterações, sempre visando prestar o melhor serviço, com a melhor
        tecnologia a Você. As atualizações da Plataforma são realizadas de forma
        automática, com correções e melhorias, assim, Você sempre utilizará a
        versão mais recente da Plataforma, não sendo possível a utilização de
        versões anteriores.
      </p>
      <p>
        <strong>2.3.</strong>
        Declaração de vontade. Os presentes Termos de Uso estabelecem obrigações
        contratadas de livre e espontânea vontade entre Você e a Bitys, e têm
        como objetivo trazer transparência e segurança para a relação jurídica
        que se estabelece a partir do momento em que Você anui expressamente com
        os Termos de Uso, concordando em segui-los estritamente, bem como
        interpretá- los em consonância com o princípio da boa-fé, estabelecido
        pelo artigo 422 do Código Civil brasileiro, e com toda a legislação
        brasileira.
      </p>
      <p>
        <strong>2.4.</strong>
        Atualização dos Termos de Uso. Estes Termos poderão ser atualizados a
        qualquer momento, sempre buscando aprimorar seus Serviços e a sua
        experiência. Neste caso, os novos Termos de Uso entrarão em vigor
        imediatamente após sua publicação na Plataforma, de forma que a
        continuidade na utilização dos Serviços representará seu consentimento
        expresso com os novos termos e condições apresentados. Em caso de
        discordância com quaisquer das alterações promovidas pela Bitys nos
        aspectos formais, procedimentais ou substanciais destes Termos de Uso,
        poderá o Cliente manifestar-se, por escrito, através do e-mail
        contato@bitys.com.br, no prazo máximo de 15 (quinze) dias, a contar de
        seu conhecimento dos novos termos e condições, para eventual negociação
        ou poderá cessar imediatamente o uso dos Serviços e solicitar o
        cancelamento do Plano contratado.
      </p>
      <p>
        <strong>2.5.</strong>
        Você concorda que, ao se cadastrar na Plataforma, anui e deverá obedecer
        a quaisquer Políticas ou Termos disponíveis sobre nossos Serviços ou que
        estejam disponíveis em nosso site.
      </p>
      <p>
        <strong>2.6.</strong>
        Informações Registradas. A Bitys não se responsabiliza pelo teor das
        informações inseridas na Plataforma pelos Usuários e pelo Cliente, o
        qual declara, desde a celebração destes Termos, por si e por seus
        empregados, serem os únicos autores destas inserções.
      </p>
      <p>
        <span>
          <strong>2.7.</strong>
          Não compreende o objeto dos Termos:

          <div class="divgpitens">
            <p>
              <strong> A.</strong>
              A cessão ou licenciamento de propriedade intelectual, direitos
              patrimoniais e morais sobre a Plataforma, a qualquer título;
            </p>
            <p>
              <strong>B.</strong>
              A disponibilização dos navegadores e equipamentos necessários para
              o acesso à Plataforma;
            </p>
            <p>
              <strong>C.</strong> Treinamento para utilização da Plataforma aos
              Colaboradores;
            </p>
            <p>
              <strong> D.</strong>
              Dentre outros itens que não estejam expressamente previstos nestes
              Termos.
            </p>
          </div>
        </span>
      </p>
    </div>

    <h3>3. CADASTRO E INFORMAÇÕES</h3>
    <div class="divgpitens">
      <p>
        <strong>3.1.</strong>
        Manutenção dos dados cadastrais atualizados. Os seus dados presentes na
        Plataforma são o meio utilizado para que a Bitys possa entrar em contato
        com Você, caso necessário. Eventual prejuízo por parte do Cliente em
        razão de erros presentes em seu cadastro, não são responsabilidade da
        Bitys, sendo de sua responsabilidade a manutenção e atualizada dos seus
        dados cadastrais, especialmente seu endereço eletrônico.
      </p>
      <p>
        <strong>3.2.</strong>
        Seu login e senha. O Serviço é acessado por meio de login e senha
        exclusivos, pessoais e intransferíveis, criados após seu cadastro na
        Plataforma. Você deverá manter seguros seu login e senha de acesso aos
        Serviços. Caso desconfie que alguém não autorizado tenha utilizado seu
        login e senha, recomendamos que Você realize a alteração de sua senha,
        que deve ser realizada dentro da Plataforma. A Bitys não se
        responsabiliza pelo acesso indevido de terceiros nas contas registadas
        na Plataforma, seja pelo compartilhamento de senha ou pelo acesso
        indevido a elas, sendo de única e exclusiva responsabilidade dos
        Usuários a manutenção e a segurança de sua conta.
      </p>
    </div>

    <h3>4. VALORES, PAGAMENTOS E PLANOS</h3>
    <div class="divgpitens">
      <p>
        <strong>4.1.</strong>
        Modificação dos valores. A Bitys notificará previamente o Cliente em
        caso de modificação ou atualização dos valores cobrados pelos Serviços,
        antes dos novos valores serem efetivados. Dessa forma os valores atuais
        pagos pelo Cliente referentes ao seu Plano atual se mantêm inalterados
        até o fim da vigência contratada, salvo hipótese de reajuste prevista no
        item 4.6 destes Termos. Decorrido o período do seu Plano atual, a
        utilização dos Serviços estará condicionada ao pagamento do novo valor
        pelo Cliente. Caso o Cliente não concorde com o novo valor contratado,
        poderá realizar Upgrade e Downgrade ou, ainda, solicitar o cancelamento
        da contratação junto à Bitys.
      </p>
      <p>
        <strong>4.2.</strong>
        Upgrade. Em caso de Upgrade, o Cliente deverá pagar o valor integral do
        novo Plano escolhido no ato da contratação, caso opte pelo pagamento com
        cartão de crédito, ou em até 3 (três) dias úteis após a contratação,
        caso opte pelo pagamento por boleto bancário. Caso o pagamento do Plano
        anterior já tenha sido efetuado, será calculada a diferença com o valor
        total do novo Plano, baseada nos dias em que a Plataforma foi
        disponibilizada (pro-rata), sendo concedido ao Cliente o desconto
        equivalente.
      </p>
      <p>
        <strong>4.3.</strong>
        Downgrade. Em caso de Downgrade, o Cliente declara estar ciente e
        concorda que a Bitys não efetuará o ressarcimento de qualquer valor já
        pago pelo Plano anterior. Eventual diferença decorrente da superioridade
        do valor pago pelo Plano anterior será abatida na mensalidade de
        migração ou nas mensalidades subsequentes do novo Plano contratado.
      </p>
      <p>
        <span>
          <strong>4.4.</strong>
          Impostos, taxas e contribuições. Todos os impostos, taxas e
          contribuições devidos pelos Serviços estão inclusos no valor do
          Plano/Pacote contratado e, consequentemente, nos valores a serem
          faturados pela Bitys.

          <div class="divgpitens">
            <p>
              <strong>4.4.1.</strong>
              No caso de instituição ou elevação de qualquer tributo que incida
              diretamente nos Serviços contratados, os preços dos Planos serão
              automaticamente ajustados a tais modificações, sendo o Cliente
              previamente comunicada pela Bitys quanto à alteração nos valores
              de seu Plano. Caso não concorde com os novos valores, Você poderá
              realizar o Downgrade do Plano ou solicitar o cancelamento da
              contratação junto à Bitys.
            </p>
          </div>
        </span>
      </p>

      <p>
        <strong>4.5.</strong>
        Atraso no pagamento. O atraso no pagamento acarretará a incidência de
        juros moratórios de 1% (um por cento) ao mês, pro rata die, e multa de
        2% (dois por cento) sobre o valor em atraso.
      </p>
      <div class="divgpitens">
        <p>
          <strong>4.5.1.</strong>
          Atraso no pagamento superior a 07 (sete) dias. Não realizado o
          pagamento do Plano em até 07 (sete) dias a contar da data de
          vencimento, Você terá seu acesso à Plataforma suspenso (o que impedirá
          o uso do sistema), ficando facultado à Bitys encaminhar a dívida não
          paga – proporcionalmente aos dias utilizados sem pagamento à protesto,
          devendo o Cliente regularizar seu débito junto ao Cartório competente,
          sendo que a liberação do acesso à Plataforma ficará sujeita à
          regularização do pagamento. Nesta hipótese, caberá ao órgão mantenedor
          notificar o Cliente anteriormente à inscrição nos cadastros de
          proteção ao crédito, não sendo de responsabilidade da Bitys proceder a
          referida notificação.
        </p>
        <p>
          <strong>4.5.2.</strong>
          Atraso no pagamento superior a 90 (noventa) dias. Decorridos 90
          (noventa) dias da data de vencimento sem que haja o pagamento dos
          valores devidos e protestados, acrescidos de juros e multa incidentes,
          haverá o cancelamento da contratação. Havendo o interesse do Cliente
          em retomar o uso da Plataforma, deverá observar as condições do item
          13.5.
        </p>
      </div>

      <p>
        <strong>4.6.</strong>
        Reajuste. O Cliente declara estar ciente e concorda de que a Bitys
        poderá reajustar anualmente os valores cobrados, conforme a variação
        positiva do índice IGP-M/FGV, ou outro que venha a substituí-lo,
        observada a data de contratação do Plano atual do Cliente.
      </p>
    </div>

    <h3>5. CAMPANHAS PROMOCIONAIS E CÓDIGOS DE DESCONTO</h3>
    <div class="divgpitens">
      <p>
        <strong>5.1.</strong>
        A Bitys poderá, a seu exclusivo critério, realizar campanhas
        promocionais, conceder benefícios ou criar e ofertar códigos
        promocionais e/ou códigos de desconto, que poderão ser resgatados para
        crédito na conta ou de outra forma e em outras condições que venham a
        ser estabelecidas em cada ação promocional.
      </p>
      <p>
        <span>
          <strong>5.2.</strong>
          O Cliente declara ter ciência de que os códigos promocionais ou
          códigos de desconto eventualmente ofertados podem expirar antes de
          serem utilizados se ultrapassado o prazo de validade informado, não
          podendo ser duplicados, transferidos, vendidos ou disponibilizados ao
          público em geral, salvo se expressamente autorizado pela Bitys, e de
          que as campanhas promocionais podem dirigir-se a um público
          específico, no qual não estará necessariamente inserido.
          <div class="divgpitens">
            <p>
              <strong>5.2.1.</strong>
              Na hipótese de ações promocionais dirigidas a um público
              específico no qual não se encaixa o Cliente, incluindo, mas não se
              limitando a benefícios ofertados exclusivamente a novos clientes
              ou, ainda, que sejam válidos somente para uma modalidade de Plano,
              dentre outros, o Cliente declara estar ciente de que não terá
              direito ao mesmo desconto e/ou benefício ofertado, permanecendo
              sob as mesmas condições contratadas e não fazendo jus a qualquer
              ressarcimento.
            </p>
          </div>
        </span>
      </p>

      <p>
        <strong>5.3.</strong>
        O Cliente está ciente de que ao realizar a contratação dos Serviços a
        partir de qualquer campanha promocional em que tenham sido concedidos
        benefícios e/ou descontos, a alteração do Plano contratado, seja para
        realizar o Downgrade ou Upgrade, poderá acarretar na perda dos
        benefícios e/ou descontos anteriormente concedidos, a depender das
        regras e condições estabelecidas no ato de contratação.
      </p>
      <p>
        <strong>5.4.</strong>
        As campanhas promocionais poderão ser ofertadas através do site,
        aplicativo ou encaminhadas ao e-mail do Cliente, a depender do
        público-alvo que se deseje atingir, e estarão condicionadas ao
        cumprimento do regulamento disponibilizado em cada campanha, estando
        ciente o Cliente de que a desobediência a qualquer das normas
        estabelecidas acarretará a perda do benefício concedido.
      </p>
      <p>
        <strong>5.5.</strong>
        Os referidos códigos promocionais não serão válidos, em qualquer
        hipótese, como dinheiro e deverão ser utilizados conforme as condições
        estabelecidas pela Bitys, devendo ser utilizados de forma legal e para a
        finalidade e o público a que se destinam, no prazo estipulado e de
        vigência de cada promoção.
      </p>
      <p>
        <strong>5.6.</strong>
        A utilização dos códigos é mera liberalidade do Cliente, estando ciente
        de que sua utilização indevida poderá acarretar a perda do benefício,
        sem que disto resulte qualquer responsabilidade para a Bitys, ou, ainda,
        poderá gerar a retenção ou dedução de vantagens que sejam obtidas
        mediante erro, fraude, ilegalidade ou violação às normas das ações
        promocionais, sem prejuízo das demais sanções previstas nos presentes
        Termos de Uso.
      </p>
    </div>

    <h3>6. SEUS DEVERES</h3>
    <div class="divgpitens">
      <p>
        <strong>6.1.</strong>
        O Cliente deve ter mais de 18 (dezoito) anos de idade completos para
        utilizar os Serviços, , e, em caso de pessoa jurídica, deve possuir os
        poderes legais legítimos para representá-la.
      </p>
      <p>
        <strong>6.2.</strong>
        O Cliente reconhece que será responsável por todas as operações
        efetuadas em sua conta na Plataforma, inclusive aquelas relacionadas ao
        pagamento, bem como pelo gerenciamento das informações inseridas e
        daquelas provenientes da utilização dos Serviços pelos seus
        Colaboradores e demais Usuários vinculados ao seu cadastro. Quaisquer
        danos que o Cliente venha a sofrer, ou sejam provocados à Bitys ou a
        terceiros, em razão de acesso à Plataforma por meio de sua conta, serão
        de responsabilidade do Cliente, uma vez que o acesso só será possível
        por meio de login e senhas dos quais o Cliente é o único detentor.
      </p>
      <p>
        <strong>6.3.</strong>
        Em sendo o Cliente pessoa jurídica, o cadastro deverá ser realizado por
        seu representante legal, o qual terá acesso ao sistema como
        Administrador e será, juntamente com a empresa, o responsável pelo
        sigilo e segurança do login e senhas, nos termos do item anterior. Caso
        o representante legal designado deixe de trabalhar com o Cliente e seja
        o único detentor das informações de acesso, o Cliente deverá entrar em
        contato com a Bitys, enviando cópia autenticada e atualizada do
        Contrato/Estatuto Social e outro documento oficial que lhe outorgue os
        devidos poderes para solicitar um novo login, que lhe será encaminhado
        em até 2 (dois) dias, a contar da data de solicitação, podendo então
        efetuar as modificações necessárias.
      </p>
      <p>
        <strong>6.4.</strong>
        Ao se comunicar com a Bitys e seus colaboradores por qualquer meio, Você
        deverá se comunicar de forma profissional e respeitosa, de acordo com
        estes Termos.
      </p>
      <p>
        <strong>6.5.</strong>
        Ao utilizar os Serviços da Bitys, as Partes declaram expressamente não
        praticar, utilizar, explorar ou aceitar a exploração de trabalho escravo
        ou em condição análoga a de escravo, degradante, em condições de
        exploração sexual de menores e de mão-de-obra infantil e adolescente,
        salvo na condição de aprendiz e em plena conformidade com a legislação
        vigente, bem como declara expressamente conduzir suas atividades em
        conformidade com todas as normas legais, incluindo a Lei Anticorrupção
        n.º 12.846/2013. Em sendo constatadas quaisquer condutas contrárias à
        legislação vigente, poderá a parte inocente tomar as medidas que
        entender necessárias, bem como comunicar as autoridades competentes,
        estando ciente a Parte Infratora de que será único e integralmente
        responsável por todas as consequências de sua conduta ilegal.
      </p>
      <p>
        <strong>6.6.</strong>
        Você é responsável pelo teor de todas as informações que introduzir na
        Plataforma, ciente de que essas informações poderão ficar armazenadas
        nos servidores da Bitys.
      </p>
      <p>
        <strong>6.7.</strong>
        Os dispositivos pelos quais Você e demais Usuários acessam a Plataforma,
        conexão à internet e navegadores, plataformas, servidores e eventuais
        algoritmos utilizados por Você são de sua exclusiva responsabilidade. A
        Bitys não se responsabiliza por quaisquer dificuldades técnicas e/ou
        estruturais que Você possa enfrentar na utilização dos seus
        equipamentos, plataformas ou sistemas operacionais ou pelo mau
        funcionamento dos Serviços inerente às falhas de conexão. Caso o acesso
        à Plataforma ocorra por meio de dispositivos móveis, poderão incidir as
        taxas e encargos da rede de dados móveis utilizada, sendo de sua
        responsabilidade exclusiva o pagamento por quaisquer uma delas.
      </p>
      <p>
        <strong>6.8.</strong>
        É de sua responsabilidade assegurar e manter seguras as estruturas
        físicas e virtuais para acesso à Plataforma, incluindo, mas sem se
        limitar ao ambiente de seu equipamento ou dispositivo, servidores,
        algoritmos e sistemas operacionais, utilizando as melhores práticas da
        indústria no que tange o desenvolvimento de software, bem como a
        utilização de ferramentas de prevenção de riscos eletrônicos, como
        antivírus e firewall, bem como software e sistemas operacionais
        atualizados e eficientes para o completo funcionamento da ferramenta,
        sendo também de sua responsabilidade proteger as suas informações de
        cadastro para acesso à Plataforma.
      </p>
      <p>
        <strong>6.9.</strong>
        Será de responsabilidade do Cliente indenizar/reembolsar a Bitys,
        inclusive em relação a honorários advocatícios e custas processuais, em
        caso de reclamação ou demanda promovida por terceiros, que decorram,
        exclusivamente, das atividades do Cliente ou demais Usuários na
        Plataforma, do seu descumprimento dos Termos de Uso e demais Políticas
        ou de qualquer violação à lei ou ao direito de terceiros.
      </p>
      <p>
        <strong>6.10.</strong>
        A Bitys não é parte de nenhuma transação realizada entre o Usuário, seus
        representantes, prepostos, colaboradores e consumidores, nem possui
        controle sobre a qualidade, segurança ou legalidade dos produtos
        ofertados pelo Cliente, e não será responsável pelo cumprimento de
        quaisquer obrigações assumidas entre as referidas partes, sendo o
        Cliente o único e exclusivo responsável pela reparação de quaisquer
        danos, acidentes, fatos ou atos decorrentes da falha na prestação dos
        seus próprios serviços e/ou vícios nos produtos que comercializa.
      </p>
      <p>
        <strong>6.11.</strong>
        Você deverá utilizar a Plataforma conforme os critérios de utilização
        definidos pela Bitys, não podendo alterar a sua programação, quebrar
        senhas ou realizar quaisquer procedimentos que venham causar prejuízos
        aos demais clientes ou a Bitys.
      </p>
      <p>
        <strong>6.12.</strong>
        Você declara e concorda, desde já, que responderá judicialmente por todo
        e qualquer dano, real ou potencial, causado pela prática de atos
        ilícitos através da Plataforma e/ou por meio do uso dos Serviços.
      </p>
      <p>
        <strong>6.13.</strong>
        O descumprimento de qualquer item dos Termos de Uso pelo Cliente,
        faculta à Bitys o direito de cancelar o acesso deste à Plataforma, sem
        prévia comunicação.
      </p>
    </div>

    <h3>7. PARÂMETROS DOS SERVIÇOS</h3>
    <div class="divgpitens">
      <p>
        <span>
          <strong>7.1.</strong>
          Obrigações da Bitys. A Bitys se compromete a:
          <div class="divgpitens">
            <p>
              <strong>A.</strong>
              Manter a disponibilidade mensal da Plataforma em 99,95% (noventa e
              nove vírgula cinco por cento) (“Nível de Serviço”) do tempo em
              funcionamento, ressalvada a ocorrência da hipótese disposta no
              item 9.4 destes Termos;
            </p>
            <p>
              <strong>B.</strong>
              Proteger os dados pessoais inseridos por Você sob caráter de
              sigilo, conforme melhores práticas e tecnologia de segurança,
              utilizando-os apenas para os fins descritos nestes Termos de Uso,
              conforme a Política de Privacidade; e
            </p>
            <p>
              <strong>C.</strong>
              Tomar as devidas providências em caso de mau funcionamento da
              Plataforma, assim como prestar o suporte técnico a Você por meio
              dos canais de atendimento disponibilizados, tudo conforme o Plano
              escolhido, fornecendo suporte técnico remoto através de seus
              profissionais ou terceiros subcontratados sob sua exclusiva
              responsabilidade.
            </p>
          </div>
        </span>
      </p>
      <p>
        <span>
          <strong>7.2.</strong>
          Casos de não aplicação do Nível de Serviço. O compromisso de Nível de
          Serviço não se aplica, caso as circunstâncias de indisponibilidade
          resultem de:

          <div class="divgpitens">
            <p>
              <strong>A.</strong>
              Interrupções planejadas, que serão informadas pela Bitys por
              e-mail, aviso na Plataforma ou informativo em seus meios de
              comunicação como redes sociais. Essas interrupções serão
              programadas, na medida do possível, em horários fora do pico de
              utilização;
            </p>
            <p>
              <strong>B.</strong>
              Casos fortuitos ou de força maior, nos termos do artigo 393 do
              Código Civil;
            </p>
            <p>
              <strong>C.</strong> Quaisquer atos ou omissões do Usuário ou de
              terceiros; e
            </p>
            <p>
              <strong>D.</strong>
              Instabilidade ou indisponibilidade das estruturas tecnológicas
              terceiras, públicas ou privadas, necessárias para a
              disponibilização da Plataforma.
            </p>
          </div>
        </span>
      </p>

      <p>
        <strong>7.3.</strong>
        Você poderá verificar o Nível de Serviço mediante requisição, nos termos
        da seção 12 destes Termos.
      </p>

      <p>
        <strong>7.4.</strong>
        Se necessário, a Bitys suspenderá seu acesso à Plataforma a qualquer
        momento e sem notificação prévia, como medida prudente ou técnica ou em
        decorrência de qualquer atividade de fiscalização realizada ou em caso
        de problemas técnicos relevantes que afetem o desempenho da Plataforma.
      </p>

      <p>
        <span>
          <strong>7.5.</strong>
          Propriedade intelectual da Bitys. A Plataforma tem seu uso licenciado
          na modalidade de serviço “Software as a Service” (SaaS) e não venda.
          Dessa forma, a titularidade e os direitos relativos à Plataforma
          pertencem exclusivamente à Bitys.

          <div class="divgpitens">
            <p>
              <strong>7.5.1.</strong>
              Todo o conteúdo da Plataforma, inclusive, mas não se limitando às
              imagens, ilustrações, informações, marcas, layout, logotipo e base
              de dados relacionados aos Serviços e à Bitys está protegido por
              direitos de propriedade intelectual e direitos autorais, todos
              pertencentes a esta. Portanto, é expressamente proibido a Você
              utilizar indevidamente qualquer conteúdo ou marcas apresentadas na
              Plataforma e/ou qualquer outro produto porventura ofertado pela
              Bitys, obrigando-se o Usuário a abster-se de realizar quaisquer
              condutas que infrinjam os direitos aqui descritos, exceto se
              previamente e expressamente autorizado por escrito pela Bitys.
            </p>
            <p>
              <strong>7.5.2.</strong>
              Sofrerá as responsabilizações cíveis, criminais e administrativas
              pelas infrações cometidas o Cliente que violar as proibições
              contidas na legislação brasileira e internacional sobre
              propriedade intelectual e nestes Termos de Uso, além de perder
              quaisquer direitos de acesso a Plataforma
            </p>
          </div>
        </span>
      </p>
    </div>

    <h3>8. LIMITAÇÃO DE RESPONSABILIDADE</h3>
    <div class="divgpitens">
      <p>
        <strong>8.1.</strong>
        Todos os serviços que venham a ser prestados à Você por terceiros, sejam
        eles parceiros ou não da Bitys, e/ou negócios eventualmente firmados
        entre as partes citadas, correrão exclusivamente entre estas, que serão
        integralmente responsáveis pelas obrigações decorrentes da prestação de
        serviços e/ou negócios celebrados entre si.
      </p>
      <p>
        <span>
          <strong>8.2.</strong>
          Não garantia. Assim como qualquer serviço prestado por meio da
          internet, a Bitys não garante a disponibilidade total da Plataforma
          sem interrupção dos Serviços, falhas gerais, falhas de segurança,
          indisponibilidades ou outros problemas que possam ocorrer durante a
          utilização dos Serviços.

          <div class="divgpitens">
            <p>
              <strong>8.2.1.</strong>
              Ainda, o Cliente declara sua inequívoca ciência de que a
              Plataforma não é livre de erros, bugs, vírus, vícios, embora a
              Bitys tenha o dever de tomar e toma todas as medidas para que isso
              não ocorra, bem como corrigir tais situações em prazo razoável, à
              medida em que identificados pelas partes.
            </p>
            <p>
              <strong>8.2.2.</strong>
              A Bitys adota todas as medidas possíveis para manter a
              confidencialidade e a segurança dos dados e informações de seus
              clientes. Você reconhece que nenhum software, servidor ou sistema
              está absolutamente imune a ataques e/ou invasões de hackers e
              outros agentes maliciosos, não sendo a Bitys responsável por
              qualquer exclusão, obtenção, utilização ou divulgação não
              autorizada de informações resultantes de ataques que não poderia
              razoavelmente evitar por meio dos seus padrões de segurança.
            </p>
          </div>
        </span>
      </p>
      <p>
        <strong>8.3.</strong>
        Serviços “as is”. A contratação dos Serviços objeto destes Termos é
        realizada “as is”, declarando expressamente o Cliente, neste ato, ter
        ciência de que os Serviços ora contratados são exatamente aqueles
        previstos neste instrumento, não estando a Bitys obrigada a fornecer
        qualquer nova funcionalidade, recurso ou customização que venha a ser
        incorporada à Plataforma posteriormente à data de celebração destes
        Termos. Novas funcionalidades, recursos ou customizações poderão, caso
        sejam de interesse do Cliente, ser contratadas separadamente, salvo nos
        casos em que a Bitys, por sua própria liberalidade, eventualmente
        exercida em caráter não- vinculante, resolver implementar novas
        funcionalidades por conta própria sem exigir previamente qualquer
        contrapartida. 8.3.1. Os Serviços atenderão às finalidades para as quais
        foram desenvolvidos. Sendo assim, a Bitys não garante que estes
        atenderão requisitos ou necessidades específicas de sua vontade ou de
        seu negócio.
      </p>
      <p>
        <strong>8.4.</strong>
        Caso fortuito, força maior e terceiros. A Bitys ficará isenta de
        qualquer responsabilidade causada por eventos decorrentes de caso
        fortuito ou força maior, incluindo os danos eventualmente causados em
        razão de fenômenos meteorológicos, falhas em sistemas de
        telecomunicação, energia elétrica, indisponibilidade de servidores,
        estruturas e plataformas físicas e/ou virtuais de terceiros nos quais o
        software da Bitys é executado e disponibilizado, orientação e navegação,
        defeito nos equipamentos do Cliente, Usuários e terceiros a ele
        vinculados utilizados para acessar a Plataforma, além de outras
        hipóteses de mau uso da Plataforma pelo Cliente e Usuários, seus
        prepostos, representantes e/ou colaboradores. Você fica desde logo
        ciente de que os programas poderão sofrer interrupções periódicas para
        atualizações e reparos, bem como em razão de falhas corrigíveis, sendo
        nestes casos observada a seção 8 destes Termos.
      </p>
      <p>
        <strong>8.5.</strong>
        Uso combinado da Plataforma. A Bitys não será responsável por eventuais
        erros e/ou interrupções comprovadamente causadas pelo uso da Plataforma
        combinado com outros software que não comportem a utilização da
        Plataforma e/ou causados por outras empresas, bem como não será
        responsável por eventuais erros e/ou interrupções da Plataforma
        ocasionados pela utilização conjunta de outros componentes, interfaces,
        algoritmos, hardware e/ou ambientes, visto que a interação da Plataforma
        com tais materiais e/ou equipamentos foge ao controle da Bitys.
      </p>
      <p>
        <strong>8.6.</strong>
        A Bitys não é, em nenhuma hipótese, responsável por quaisquer danos
        indiretos ou reflexos, morais, punitivos, lucros cessantes, perda de
        oportunidade, independentemente de qualquer aviso.
      </p>
      <p>
        <strong>8.7.</strong>
        Informações das páginas públicas. As informações que constam na
        Plataforma e páginas públicas da Bitys poderão mudar eventualmente a seu
        exclusivo critério. Tentaremos manter as informações sempre atualizadas,
        mas não garantimos que estarão corretas a todo momento.
      </p>
      <p>
        <strong>8.8.</strong>
        Você e a Bitys envidarão os melhores esforços para evitar ou mitigar a
        responsabilização da outra por fatos relacionados às suas respectivas
        atividades econômicas e seus riscos, sendo que ambas declaram ter
        ciência do seu dever de mitigar os prejuízos causados pela parte
        contrária (“duty to mitigate the loss”).
      </p>
    </div>

    <h3>9. CONFIDENCIALIDADE</h3>
    <div class="divgpitens">
      <p>
        <strong>9.1.</strong>
        A Bitys e Você obrigam-se reciprocamente, inclusive em nome de seus
        empregados, cooperados, prepostos e terceiros que participem de qualquer
        forma da execução do objeto dos presentes Termos, a manter o mais
        completo e absoluto sigilo sobre toda e qualquer Informação
        Confidencial.
      </p>

      <p>
        <strong>9.2.</strong>
        É vedado às partes, sob qualquer pretexto, direta ou indiretamente,
        divulgar e/ou revelar, armazenar, copiar, reproduzir ou utilizar para
        fins distintos do objeto destes Termos, as Informações Confidenciais,
        sem a autorização prévia e expressa da parte que revelou a Informação
        Confidencial.
      </p>

      <p>
        <span>
          <strong>9.3.</strong>
          Não serão consideradas Informações Confidenciais aquelas que:

          <div class="divgpitens">
            <p>
              <strong>A.</strong>
              Já eram ou se tornaram de domínio público à época da revelação; e
            </p>
            <p>
              <strong>B.</strong>
              Tenham sido licitamente obtidas pelas partes por meio de
              terceiros, sem violação direta ou indireta destes Termos.
            </p>
          </div>
        </span>
      </p>

      <p>
        <strong>9.4.</strong>
        Caso as Informações Confidenciais devam ser divulgadas em virtude de lei
        ou ordem judicial, a parte que receber a ordem deverá comunicar à parte
        titular da Informação Confidencial, se permitido, antes da divulgação,
        para que esta adote as medidas que entender necessárias para evitar a
        revelação. Se a divulgação da Informação Confidencial não puder ser
        evitada, a parte que tiver a obrigação legal de revelá-la deverá fazê-lo
        nos exatos limites determinados na norma legal ou na respectiva ordem
        judicial.
      </p>
      <p>
        <strong>9.5.</strong>
        Em caso de descumprimento de qualquer cláusula desta seção, a parte
        infratora será responsável, civil e criminalmente em virtude da quebra
        da confidencialidade e sigilo a que está obrigada, pelos danos causados
        à outra parte e/ou a terceiros prejudicados, desde que devidamente
        comprovados, devendo responder por seus próprios atos e de seus
        administradores, empregados, prepostos, colaboradores, subcontratados e
        demais pessoas a ela ligadas a qualquer título. A parte que violar as
        condições previstas nesta cláusula, dará justo motivo para a resolução
        contratual, incorrendo nas penalidades já previstas nestes Termos, sem
        prejuízo da reparação de eventuais perdas e danos suportados pela parte
        prejudicada.
      </p>
    </div>

    <h3>10. DADOS PESSOAIS E POLÍTICA DE PRIVACIDADE</h3>
    <div class="divgpitens">
      <p>
        <strong>10.1.</strong>
        O tratamento dos Dados Pessoais necessário ao cumprimento destes Termos
        será realizado pela Bitys (“Operadora”) em nome do Cliente
        (“Controladora”), baseado nas melhores práticas de mercado atinentes à
        segurança da informação, possuindo uma Política de Privacidade e
        Tratamento de Dados disponível no endereço:
        https://bitys.com.br/politica-de-privacidade/, com a qual, desde já,
        Você declara que leu e concorda.
      </p>
      <p>
        <strong>10.2.</strong>
        A Operadora obriga-se a (a) cumprir a LGPD, a todo momento e de forma
        completa, adotando medidas técnicas, administrativas e organizacionais
        adequadas à natureza das suas atividades, mantendo comprovações de tal
        cumprimento, apresentando-as à Controladora, quando por esta solicitado.
      </p>
      <p>
        <strong>10.3.</strong>
        A Operadora obrigada, ainda a realizar o tratamento dos Dados Pessoais
        de acordo com as instruções previstas na LGPD e para a execução deste
        Termo, sendo permitido o Tratamento posterior para outra finalidade
        relacionada ou compatível, desde que admita pela legislação vigente e
        após a entrada em vigor da LGPD devidamente justificada em uma das bases
        legais previstas pela LGPD, ou em casos de cumprimento de obrigação
        legal ou regulatória, situações nas quais a Operadora passará a ser
        Controladora em relação ao tratamento dos Dados Pessoais para tais
        finalidades.
      </p>
      <p>
        <span>
          <strong>10.4.</strong>
          Ainda, a Operadora informará à Controladora (i) caso esteja sujeita a
          qualquer legislação e/ou regulamento que a impeça de cumprir quaisquer
          disposições aqui indicadas e da LGPD; (ii) caso deixe de estar em
          conformidade com as obrigações aqui previstas, com as disposições da
          LGPD e demais legislações aplicáveis, sendo garantido à Controladora,
          neste caso, o direito de rescindir estes Termos, sem qualquer
          penalidade ou pagamento, ou suspender a execução destes Termos
          enquanto a Operadora não estiver em conformidade as disposições aqui
          previstas.

          <div class="divgpitens">
            <p>
              <strong>10.4.1.</strong>
              A Operadora deve destruir ou devolver, conforme orientação da
              Controladora, a integralidade dos Dados Pessoais, incluindo cópias
              existentes no caso de término de qualquer relacionamento formal
              com a Controladora, salvo se acordado de outra forma com a
              Controladora, e ressalvadas as hipóteses de manutenção dos Dados
              Pessoais em decorrência de obrigação legal ou regulatória,
              situação na qual a Operadora passará a ser Controladora em relação
              ao tratamento dos Dados Pessoais exclusivamente para tal
              finalidade.
            </p>
          </div>
        </span>
      </p>
      <p>
        <strong>10.5.</strong>
        A Controladora obriga-se a (i) disponibilizar expressamente à Operadora
        todas as instruções relacionadas ao tratamento de Dados Pessoais
        necessárias à prestação dos Serviços, que deverão ser observadas pela
        Operadora; (ii) cumprir a LGPD e demais legislações aplicáveis, a todo
        momento e de forma completa, adotando medidas técnicas, administrativas
        e organizacionais adequadas à natureza das suas atividades, mantendo
        comprovações de tal cumprimento; (iii) cooperar com a Operadora no
        cumprimento das obrigações referentes ao exercício dos direitos dos
        titulares previstos na LGPD e demais legislações aplicáveis, e no
        atendimento a eventuais solicitações de autoridades fiscalizadoras; (iv)
        auxiliar a Operadora, no que lhe couber, no cumprimento das obrigações
        inerentes à qualidade de Operadora, previstas na LGPD e demais
        obrigações aplicáveis; (v) adotar todas as medidas necessárias com
        vistas a garantir que o tratamento dos Dados Pessoais seja enquadrado em
        uma das bases legais de tratamento previstas na LGPD; (vi) respeitar os
        direitos dos titulares previstos na LGPD e responder às solicitações dos
        titulares, além de disponibilizar para os titulares todas as informações
        obrigatórias previstas na LGPD e nas demais legislações aplicáveis; e
        (vii) notificar, quando exigido pela LGPD, a ANPD (Autoridade Nacional
        de Proteção de Dados) e os titulares, conforme o caso, na hipótese de
        violação de Dados Pessoais, nos prazos indicados na LGPD e normativos da
        ANPD (Autoridade Nacional de Proteção de Dados).
      </p>
      <p>
        <span>
          <strong>10.6.</strong>
          Todas as notificações e demais comunicações entre as Partes cujo
          objeto esteja relacionado ao tratamento de Dados Pessoais deverão ser
          realizadas por escrito, e enviadas para contato@bitys.com.br.

          <div class="divgpitens">
            <p>
              <strong>10.6.1.</strong>
              A Parte que tiver alterado os dados de contato deverá comunicar as
              alterações à outra Parte. Até que seja feita essa comunicação,
              serão válidos e eficazes os avisos, as comunicações, as
              notificações e as interpelações enviadas para o contato constante
              acima.
            </p>
          </div>
        </span>
      </p>
      <p>
        <strong>10.7.</strong>
        As Partes reconhecem que todas as mensagens enviadas por meio eletrônico
        constituem evidência e prova legal em âmbito judicial.
      </p>
    </div>

    <h3>11. SUPORTE</h3>
    <div class="divgpitens">
      <p>
        <strong>11.1.</strong>
        A Bitys oferecerá suporte básico e remoto com relação aos Serviços,
        funcionalidades e recursos disponibilizados na Plataforma e aplicativo,
        o que implica no esclarecimento de dúvidas com relação ao uso do
        sistema, bem como aos Planos disponíveis, preços, eventuais problemas
        com relação a pagamentos e erros decorrentes da própria Plataforma e/ou
        aplicativo.
      </p>
      <p>
        <strong>11.2.</strong>
        O suporte remoto ao Cliente poderá ocorrer por meio de chat online
        disponível na Plataforma ou através do e-mail contato@bitys.com.br,
        conforme o Plano contratado, comprometendo-se a Bitys a responder
        quaisquer dúvidas o mais rapidamente possível. O atendimento será
        realizado nos dias úteis, em horário comercial, das 09:00 às 18:00h,
        conforme o fuso horário de Brasília.
      </p>
      <p>
        <strong>11.3.</strong>
        O Cliente está ciente de que o serviço de suporte somente será prestado
        aos Usuários cadastrados como Administradores ou Gestores da conta, no
        gozo do período do Plano, e desde que estejam utilizando o software da
        Bitys atualizado, na última versão disponibilizada pela Bitys, e caso
        não possua qualquer pendência financeira.
      </p>
    </div>

    <h3>12. CANCELAMENTO</h3>
    <div class="divgpitens">
      <p>
        <span>
          <strong>12.1.</strong>
          Ressarcimento. A Bitys garante ao Cliente, por sua mera liberalidade,
          o cancelamento do Plano Pago contratado junto ao ressarcimento do
          valor pago, desde que o Cliente tenha solicitado referido cancelamento
          em até 07 (sete) dias corridos da data da contratação. Nesta hipótese
          a Bitys realizará o ressarcimento do valor pago no prazo de até 07
          (sete) dias úteis, a contar da solicitação de cancelamento feita pelo
          Cliente.
          <div class="divgpitens">
            <p>
              <strong>12.1.1.</strong>
              A solicitação de cancelamento que trata o item anterior deve ser
              realizada por meio do e-mail contato@bitys.com.br.
            </p>
            <p>
              <strong>12.1.2.</strong>
              A hipótese a que se refere o item 12.1 poderá ocorrer somente no
              primeiro período de contratação de cada modalidade de Plano, seja
              ele Mensal ou Anual, devendo o Cliente solicitar o cancelamento
              nos termos desta seção, se desejar cancelar seu Plano nos
              meses/anos subsequentes.
            </p>
            <p>
              <strong>12.1.3.</strong>
              O ressarcimento a ser realizado pela Bitys, unicamente na hipótese
              do item 12.1, dar-se-á mediante transferência bancária, devendo o
              Cliente informar seus dados bancários (banco, agência e conta
              corrente ou poupança) que possibilite o crédito em sua conta.
            </p>
            <p>
              <strong>12.1.4.</strong>
              Em não possuindo o Cliente conta corrente ou poupança na mesma
              instituição bancária da conta da Bitys, esta efetuará o
              ressarcimento via DOC ou TED, estando ciente o Cliente de que do
              valor a ser devolvido será deduzida a tarifa cobrada pela
              instituição financeira por estes serviços (DOC/TED).
            </p>
            <p>
              <strong>12.1.5.</strong>
              No caso de pagamentos efetuados via cartão de crédito ou via
              gateways, será realizado o cancelamento da venda dentro das
              conformidades e prazos do banco e da bandeira do cartão de crédito
              utilizados pelo Cliente.
            </p>

            <p></p>
          </div>
        </span>
      </p>
      <p>
        <span>
          <strong>12.2.</strong>
          Cancelamento do Plano Mensal. O Cliente que possuir um Plano Mensal
          poderá, a qualquer tempo, solicitar o cancelamento de seu Plano
          através do e-mail contato@bitys.com.br, sendo bloqueado seu acesso em
          3 (três) dias após o processamento da solicitação pela Bitys. Não
          haverá o ressarcimento dos valores já pagos pelo Cliente.
          <div class="divgpitens">
            <p>
              <strong>12.2.1.</strong>
              Ao solicitar o cancelamento nos termos do item acima, o Cliente
              terá o prazo de 03 (três) dias para realizar o backup das
              informações vinculadas à sua conta, sendo de sua integral
              responsabilidade a impressão e/ou arquivamento dos relatórios
              gerados pela sua utilização da Plataforma.
            </p>
            <p>
              <strong>12.2.2.</strong>
              O cancelamento do Plano Mensal durante o período de fidelidade, ou
              seja, antes de completar os 06 (seis) primeiros meses de vigência
              deste Termo, acarretará o pagamento pelo Cliente de multa
              equivalente a 01 (uma) mensalidade integral para Clientes com
              menos de 10 (dez) usuários e multa equivalente a 03 (três)
              mensalidades integrais para Clientes com mais de 10 (dez)
              usuários, de acordo com as informações fornecidas pela Bitys no
              momento da contratação do Plano.
            </p>
          </div>
        </span>
      </p>
      <p>
        <strong>12.3.</strong>
        Reativação de conta. Caso deseje reativar sua conta, inclusive com
        acesso a todas as informações anteriormente inseridas, o Cliente deverá
        realizar a solicitação através do e-mail contato@bitys.com.br, no prazo
        de até 06 (seis) meses a contar da data de cancelamento, bem como
        efetuar o pagamento da mensalidade do Plano a ser reativado, estando
        ciente o Cliente de que o valor cobrado será equivalente ao valor da
        mensalidade vigente à época da reativação. Ultrapassado este período,
        deverá ser realizada nova contratação, conforme as condições disponíveis
        nas páginas públicas da Bitys.
      </p>
      <p>
        <strong>12.4.</strong>
        Os direitos e obrigações cabíveis à Bitys e o Cliente no que tange à
        propriedade intelectual, confidencialidade e tratamento de dados
        continuarão vigentes mesmo após o cancelamento da sua conta.
      </p>
    </div>

    <h3>13. DISPOSIÇÕES GERAIS</h3>
    <div class="divgpitens">
      <p>
        <strong>13.1.</strong>
        Você concorda que em caso de quaisquer conflitos advindos da sua relação
        de Cliente com a Bitys aplicar-se-á a legislação brasileira vigente.
      </p>
      <p>
        <strong>13.2.</strong>
        Você autoriza neste ato a utilização e a divulgação de seu nome e marca
        como cliente da Bitys para fins de estratégia de marketing, propagandas
        e publicidade em geral desta, o que inclui a utilização do seu nome e
        logo no site da Bitys, bem como nos materiais de marketing publicado nas
        redes sociais desta, e-mails, site, ou qualquer outra forma de
        divulgação que a Bitys deseje utilizar.
      </p>
      <p>
        <strong>13.3.</strong>
        Em decorrência destes Termos, não deverá emergir nenhuma relação
        trabalhista ou previdenciária entre a Bitys e os empregados, prepostos
        ou terceiros indicados do Cliente. Na hipótese de serem ajuizadas,
        contra a Bitys, demandas trabalhistas, previdenciárias, cíveis ou
        tributárias, relativamente aos empregados e/ou terceiros do Cliente, ou
        na eventualidade da Bitys receber notificações do Fisco Federal,
        Estadual e Municipal incluindo, mas não limitadas ao Ministério do
        Trabalho do INSS e da Caixa Econômica Federal etc., o Cliente obriga-se
        a intervir no processo judicial (ou procedimento administrativo,
        conforme o caso), na qualidade de parte legítima, reivindicando para si
        as obrigações exigidas, requerendo a exclusão da Bitys. Em tais
        situações, o Cliente, desde já, compromete-se a indenizar a Bitys,
        mediante notificação por escrito, por todas as despesas, honorários
        advocatícios e custos que esta venha a incorrer.
      </p>
      <p>
        <strong>13.4.</strong>
        Na hipótese de conflito de cláusulas entre o disposto nestes Termos de
        Uso e outros contratos ou propostas eventualmente firmadas, prevalecerá
        o disposto nestes Termos.
      </p>
      <p>
        <strong>13.5.</strong>
        Estes Termos não estabelecem qualquer forma de associação, franquia,
        parceria, consórcio, joint-venture, vínculo empregatício, societário ou
        solidariedade entre a Bitys e o Cliente, tampouco confere às partes
        permissão para praticar quaisquer atos, contratar ou assumir obrigações
        em nome da outra parte.
      </p>
      <p>
        <strong>13.6.</strong>
        No caso de alguma cláusula pertencente a estes Termos ou qualquer outra
        Política da Bitys seja declarada nula, inaplicável ou sem efeito, apenas
        esta será afetada, e continuam a viger o restante das cláusulas dos
        presentes Termos.
      </p>
      <p>
        <strong>13.7.</strong>
        A eventual tolerância quanto a qualquer violação dos Termos de Uso ou
        Políticas da Bitys será considerada mera liberalidade e não será
        interpretada como novação, precedente invocável, renúncia a direitos,
        alteração tácita dos termos contratuais, direito adquirido ou alteração
        contratual.
      </p>
      <p>
        <strong>13.8.</strong>
        A Bitys poderá introduzir modificações, aditivos e anexos a este Termo,
        sendo que eventuais alterações serão comunicadas devidamente ao Cliente
        através de envio de correspondências e/ou divulgação nos canais
        tecnológicos disponibilizados pela Bitys. Caso não haja manifestação
        contrária por parte do Cliente em até 10 (dez) dias a contar da data de
        tal divulgação, ou, se neste período o Cliente realizar nova contratação
        de novo Serviço, implicará, de pleno direito, a aceitação e adesão
        irrestrita do Cliente às novas condições estabelecidas.
      </p>
      <p>
        <strong>13.9.</strong>
        Fica eleito o Foro Central da Comarca de Curitiba, Estado do Paraná,
        para dirimir quaisquer dúvidas ou conflitos advindos dos presentes
        Termos de Uso ou demais Políticas da Bitys.
      </p>
    </div>

    <div style="text-align: right">
      <br />
      <p>Curitiba/PR, março de 2024.</p>
      <br />
    </div>
    <div style="text-align: center">
      <div><strong>BITYS LTDA</strong></div>
      <div>CNPJ nº 26.197.870/0001-96</div>
      <br />
    </div>`

export default objContrato

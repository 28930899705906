import React from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import AppMain from '../AppMain'

export default function App(props) {
  const { width, ref } = useResizeDetector()

  const enableClosedSidebar = useSelector((state) => state.enableClosedSidebar)
  const enableMobileMenu = useSelector((state) => state.enableMobileMenu)

  let { enableFixedHeader = true, enableFixedSidebar = true, enableFixedFooter = true, closedSmallerSidebar } = props

  return (
    <div
      ref={ref}
      className={cx(
        'app-container app-theme-white',
        { 'fixed-header': enableFixedHeader },
        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
        { 'fixed-footer': enableFixedFooter },
        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
        { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
        { 'sidebar-mobile-open': enableMobileMenu }
      )}
    >
      <AppMain />
    </div>
  )
}

import styled from 'styled-components'

export const Title = styled.p`
  padding: 5px 0 0 0;
  font-size: 1.2rem;
`
export const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 1.2rem;
    line-height: 1.334;
    letter-spacing: 0em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: var(--gray-dark);
  }
`
export const BoxTermo = styled.div`
  background-color: #e6e6e6;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
  white-space: pre-wrap;
  overflow: auto;
  max-height: 65vh;
  h3 {
    color: #4154f1;
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0 2px 0;
  }
  p {
    margin: 5px 0 5px 0;
  }
  div {
    margin: 5px 0 0 10px;
    white-space: initial;
  }
`
export const BoxOferta = styled.div`
  background: #fff;
  padding: 20px 30px 10px;
  border-radius: 8px;

  > h1 {
    line-height: 0;
    padding: 20px 0;
    border-radius: 4px;
    color: #fff;
    background: #4154f1;
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);

    font-size: 16px;
    text-align: center;
  }

  > h2 {
    font-size: 16px;
    display: flex;
    flex-direction: column;

    padding: 0 4px;

    > span {
      font-weight: 600;
    }

    span:nth-child(2) {
      margin-left: auto;
    }
    > p {
      margin: 2px 0 2px 15px;
    }
  }

  > div {
    font-size: 16px;
    display: flex;
    flex-direction: row;

    padding: 0 4px;

    > span {
      font-weight: 600;
    }

    span:nth-child(2) {
      margin-left: auto;
    }
  }
`
export const Button = styled.button`
  width: 50%;
  padding: 8px 0;

  margin: auto;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white);
  background: #4154f1 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: none;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :hover {
    transform: scale(1.1);
  }

  @media (max-width: 688px) {
    width: 100%;
  }
`

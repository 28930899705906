import styled from 'styled-components'

export const CardCopy = styled.div`
  background: var(--primary);
  color: var(--secondary);
  font-size: 13px;
  @media (max-width: 688px) {
    margin: auto 0px;
    > div > span {
      text-align: center;
      width: 100%;
    }
    span:nth-child(2n) {
      display: none;
    }
  }
`

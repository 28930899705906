import styled from 'styled-components'

export const LblDetails = styled.div`
  font-weight: 600;
  width: 100%;
  margin-left: 6px;
`
export const ContainerHtml = styled.div`
  width: 100%;
  margin-left: 6px;

  display: flex;
  flex-direction: column;
`

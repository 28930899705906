import React from 'react'
import { ContainerHtml, LblDetails } from './styles'
import { Col, Label } from 'reactstrap'

export default function Element({ md, label, text, isHTML = false }) {
  return (
    <Col md={md} className="mb-2">
      <Label> {label} </Label>
      {isHTML ? <ContainerHtml dangerouslySetInnerHTML={{ __html: text }} /> : <LblDetails>{text}</LblDetails>}
    </Col>
  )
}

import React, { useState } from 'react'

import { Modal, ModalBody } from 'reactstrap'
import { BoxTermo, ContainerForm, Title, BoxOferta, Button } from './styles'
import { toast, Bounce, Loading } from '../../../../Components'

import Contrato from './contrato'
import { ContaAceite } from '../../../../Service/ApiService'
export default function Element({ contaId, change }) {
  const [isLoading, setIsLoading] = useState(false)

  async function onConcordo() {
    try {
      setIsLoading(true)
      var response = await ContaAceite(contaId)

      if (response?.result === true) change(true)
      else if (response?.message) {
        toast(response.message, { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        change(false)
      } else {
        toast('Ocorreu um problema ao salvar a sua confirmação, entre em contato com o suporte técnico!', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <>
      <Loading isVisible={isLoading} />

      <Modal style={{ margin: 'auto' }} isOpen={true} size="lg">
        <ModalBody>
          <ContainerForm>
            <Title>Termos de uso MikroBit</Title>

            <BoxTermo id="boxTermo">
              <div
                dangerouslySetInnerHTML={{ __html: Contrato }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              />
              <BoxOferta>
                <h1>
                  Oferta selecionada: <strong>Corporativo completo</strong>
                </h1>
                <h2>
                  <span>Chat</span>
                  <p>Dashboard | Conversas | Canais | Filas | Bot</p>
                </h2>
                <h2>
                  <span>CRM</span>
                  <p>Dashboard | Funil</p>
                </h2>
                <h2>
                  <span>Campanhas</span>
                  <p>Dashboard | Links rastreáveis</p>
                </h2>

                <hr />
                <div>
                  <span>Total</span>
                  <span>R$ 790,00/mês</span>
                </div>
              </BoxOferta>
            </BoxTermo>

            <Button color="success" type="button" onClick={onConcordo}>
              Concordo
            </Button>
          </ContainerForm>
        </ModalBody>
      </Modal>
    </>
  )
}

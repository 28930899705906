import React, { useEffect } from 'react'
import { UsuarioGetAcessos } from '../../Service/ApiService'
import { putUserAccessMenu } from '../../Reducers/storageApp'
import { Biz_IsAccessCodigo } from '../../Service/BizService'

export default function Element({ onChange }) {
  async function User_LoadMenu() {
    const acessos = await UsuarioGetAcessos()

    let menu = []
    if (acessos) {
      if (Biz_IsAccessCodigo('Dashboard_Q') === true) menu.push({ icon: 'fa fa-chart-pie', label: 'Dashboard', to: '/#/Dashboard' })
      else menu.push({ icon: 'fa fa-list', label: 'Home', to: '/#/' })

      acessos.forEach((itemG) => {
        if (itemG.grupo === 'Clientes') menu.push({ icon: 'fa fa-users', label: 'Participantes', to: '/#/Clients' })
        else if (itemG.grupo === 'Chat') menu.push({ icon: 'fa fa-commenting-o', label: 'Chat', to: '/#/Chat' })
        else if (itemG.grupo === 'CRM') menu.push({ icon: 'fa fa-briefcase', label: 'CRM', to: '/#/CRM/Funnel' })
        else if (itemG.grupo === 'Campanhas') menu.push({ icon: 'fa fa-bullseye', label: 'Campanhas', to: '/#/Campaign' })
        else if (itemG.grupo === 'Patrimônios') menu.push({ icon: 'fa fa-archive', label: 'Patrimônios', to: '/#/Property/Query' })
        else if (itemG.grupo === 'Projetos') menu.push({ icon: 'fa-solid fa-chart-gantt', label: 'Projetos', to: '/#/Project' })
        else if (itemG.grupo === 'Tarefas') menu.push({ icon: 'fa-regular fa-circle-check', label: 'Tarefas', to: '/#/Tasks' })
        else if (itemG.grupo === 'Configurações') menu.push({ icon: 'fa fa-gear', label: 'Configurações', to: '/#/Settings' })
        else if (itemG.grupo === 'Relatórios') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'RepLead_Q') menuGrupo.push({ label: 'Leads', to: '/#/Reports/Leads' })
            else if (itemM.codigo === 'RepTimesheet_Q') menuGrupo.push({ label: 'Timesheet', to: '/#/Reports/Timesheet' })
          })

          if (menuGrupo.length > 0) menu.push({ icon: 'fa fa-chart-bar', label: 'Relatórios', content: menuGrupo })
        }
      })
    }
    await putUserAccessMenu(acessos, menu)
    onChange()
  }

  useEffect(() => {
    User_LoadMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

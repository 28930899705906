import { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { Button, Card, CardBody, Collapse } from 'reactstrap'
import { Header } from './styles'

export default function CardFilter({ children, onChange }) {
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  return (
    <Card className="main-card mb-1">
      <Header>
        <span>Filtros</span>
        <div>
          <Button
            type="button"
            onClick={() => {
              onChange()
              setIsOpenFilter(false)
            }}
            className="btn-pill"
            color="primary"
          >
            <BsSearch size={18} /> Buscar
          </Button>
          <Button type="button" onClick={() => setIsOpenFilter(!isOpenFilter)} className="ml-2" color="outline-primary" id="btCollapse">
            {isOpenFilter ? <MdExpandLess size={22} /> : <MdExpandMore size={22} />}
          </Button>
        </div>
      </Header>
      <Collapse isOpen={isOpenFilter}>
        <CardBody>{children}</CardBody>
      </Collapse>
    </Card>
  )
}

import React, { useEffect, useState } from 'react'
import { toast, Bounce } from 'react-toastify'
import { AltSenha, IsHashValido } from '../../../Service/ApiService'
import { ContainerForm, Input, Button } from '../styles'
import { useParams, useSearchParams } from 'react-router-dom'

import ModContract from './Contract'

export default function Element(props) {
  const [values, setValues] = useState({ password: '', confirmPassword: '', hash: '' })
  let { id } = useParams()
  const [modContract, SetModContract] = useState({ show: false, contaId: 0 })
  const [urlQuery] = useSearchParams()

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()

    if (values.password.length < 6) {
      toast('Informe uma senha válida!', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (values.confirmPassword !== values.password) {
      toast('As senhas não coincidem!', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    try {
      let stringToCodify = values.password
      let encoded = btoa(stringToCodify)

      const req = await AltSenha({ senha: encoded, hash: values.hash })
      if (req.result) {
        toast('Senha alterada com sucesso', { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      } else {
        toast(req.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    } catch (error) {
      toast(`${error}`, { type: 'error', transition: Bounce, closeButton: true, autoClose: 500, position: 'top-right' })
    }

    setTimeout(function () {
      window.location.href = '/#/Login'
    }, 5000)
  }

  async function validaHash(hash) {
    const result = await IsHashValido(hash)
    if (!result.result) {
      toast(result.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      setTimeout(function () {
        window.location.href = '/#/Login'
      }, 5000)
    } else {
      setValues({ ...values, hash: hash })
      if (urlQuery.get('ct')) SetModContract({ show: true, contaId: urlQuery.get('ct') })
    }
  }

  useEffect(() => {
    if (id) validaHash(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  function onContrato(ev) {
    SetModContract({ ...modContract, show: false })
    if (ev == true) toast('Cadastre a sua senha de acesso ao portal', { type: 'success', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    else {
      setTimeout(function () {
        window.location.href = '/#/Login'
      }, 5000)
    }
  }

  return (
    <>
      {modContract.show && <ModContract change={onContrato} contaId={modContract.contaId} />}
      <ContainerForm>
        <Input type="password" name="password" placeholder="Nova Senha" onChange={onChange} value={values.password} />
        <Input type="password" name="confirmPassword" placeholder="Confirme a Nova Senha" onChange={onChange} value={values.confirmPassword} />
        <Button onClick={onSubmit}> Salvar </Button>
      </ContainerForm>
    </>
  )
}

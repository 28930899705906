import React, { Fragment } from 'react'
import Lottie from 'react-lottie'
import animationData from '../Loading/animationData.json'
import { Wrapper, Container, MsgLoading } from './styles'

export default function Element({ isVisible, placeholder }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  }
  return (
    <Fragment>
      {isVisible && (
        <Wrapper>
          <Container>
            <Lottie options={defaultOptions} style={{ maxHeight: '200px', maxWidth: '200px' }} />

            <MsgLoading>{placeholder}</MsgLoading>
          </Container>
        </Wrapper>
      )}
    </Fragment>
  )
}
